@import 'src/styles/variables';

.menuList {
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 0;

  @media (min-width: $bp2) {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.menuItem {
  line-height: 1.5;

  &:not(:last-of-type) {
    margin-bottom: 15px;
  }

  @media (min-width: $bp2) {
    &:not(:last-of-type) {
      margin-bottom: 0;
      margin-right: 1em;
    }
  }
}

.navLink {
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  color: $color-dark;
  text-transform: uppercase;

  &.isCurrent {
    font-weight: 700;
  }
}
