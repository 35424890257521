@import 'src/styles/variables';

.container {
  margin-top: 120px;

  @media (min-width: $bp1) {
    margin-top: 180px;
  }
}

.category {
  padding: 24px;
  border-radius: 4px;
  background-color: $color-gray020;

  [data-reach-disclosure-panel] {
    &:focus {
      outline: none;
    }
  }
}

.firstCategory {
  margin-top: 40px;
}

.secondCategory {
  margin-top: 16px;

  @media (min-width: $bp1) {
    margin-top: 32px;
  }
}

.title {
  font-family: var(--poppins-font);
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0;
  text-align: left;

  &:focus {
    outline: none;
  }

  @media (min-width: $bp1) {
    cursor: default;
    pointer-events: none;

    svg {
      display: none;
    }
  }
}

.itemsList {
  margin-top: 24px;
  list-style-type: none;
  padding: 0;

  @media (min-width: $bp1) {
    column-count: 2;
  }

  @media (min-width: $bp3) {
    column-count: 3;
  }
}

.listItem {
  color: $color-gray3;
  font-size: 24px;
  line-height: 28px;

  &:not(:first-of-type) {
    margin-top: 8px;
  }
}
