@import 'src/styles/variables';

.heading {
  position: relative;
}

.titleSpan {
  position: absolute;
  top: -40px;

  @media (min-width: $bp2) {
    top: -120px;
  }
}
