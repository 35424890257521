@import 'src/styles/variables';

.title {
  font-size: 32px;
  line-height: '40px';
  margin: 0;

  @media (min-width: $bp2) {
    font-size: 40px;
    line-height: '48px';
  }
}

.rhombus {
  width: 24px;
  height: 24px;
  transform: rotate(45deg);
  background-color: $color-attention;
  display: inline-block;
  margin-bottom: 16px;
  border-radius: 2px;

  @media (min-width: $bp2) {
    width: 32px;
    height: 32px;
  }
}
