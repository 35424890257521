@import 'src/styles/variables';

.container {
  background-color: $color-marmolClaro;
  padding: 18px;
  display: flex;
  align-items: center;
  position: relative;
  width: calc(100% - 15px);
  margin: 36px 0 40px;
  border-radius: 4px;

  @media (min-width: $bp1) {
    margin: 40px 0 0;
    max-height: 130px;
  }
}

.icon {
  margin-right: 20px;

  svg {
    width: 50px;
    height: 50px;
  }
}

.text {
  font-size: 14px;
  line-height: 18px;
}

.emailCount {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-teja;
  color: $color-white;
  font-size: 18px;
  position: absolute;
  right: -15px;
  top: -15px;
}

.dosisText {
  font-weight: 700;
  display: block;
}
