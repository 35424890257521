@import 'src/styles/variables';
@import 'src/styles/mixins';

.url {
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  flex: 1;
  white-space: nowrap;
  font-weight: 700;
  font-size: 14px;
  color: $color-dark;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  margin-right: 10px;

  &:focus {
    outline: none;
  }

  @media (min-width: $bp3) {
    font-size: 18px;
  }
}

.copyButton {
  @include iconButton(40px);
}

.copyButtonIsCopied {
  @include iconButton(40px, $color-attention);
}

.container {
  margin-top: 50px;
  max-width: 600px;
}

.shareButton {
  @include button(false);
  display: flex;
  align-items: center;

  svg {
    margin-left: 8px;
  }
}

.copyTitle {
  margin-left: 10px;
}

.copy {
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: $color-gray0;
  padding: 8px 20px;
  margin-top: 5px;
}
