@import 'src/styles/variables';

.wrapper {
  @media (min-width: $bp1) {
    width: 42vw;
  }

  img {
    border-radius: 4px;
  }

  @media (min-width: $bp4) {
    width: auto;
  }
}
