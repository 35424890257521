@import 'src/styles/variables';

.highlight {
  padding: 24px;
  margin: 32px auto;
  width: 90vw;
  border-radius: 4px;
  max-width: 100%;

  p {
    margin: 0;
    line-height: 1.4;
  }

  a {
    color: $color-dark;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-color: $color-dark;
    text-decoration-thickness: 1px;

    &:hover {
      color: $color-teja;
      text-decoration-color: $color-teja;
      text-decoration-thickness: 3px;
    }
  }

  @media (min-width: $bp2) {
    margin: 72px auto;
    width: auto;
  }

  &.attention {
    background-color: $color-white;
    border: 2px solid $color-attention;
  }

  &.note {
    background-color: $color-gray0;
  }
}
