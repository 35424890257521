.actions {
  margin-top: 2rem;
}

.passwordButton {
  position: absolute;
  top: 37px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.errors {
  margin-top: 20px;
}
