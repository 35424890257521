@import 'src/styles/variables';

.photo {
  overflow: hidden;
  max-width: 33%;

  @media (min-width: $bp1) {
    max-width: 180px;
  }

  @media (min-width: $bp2) {
    flex-shrink: 0;
  }

  img {
    width: 56px;
    height: 56px;
    display: block;
    object-fit: cover;
    border-radius: 50%;
  }
}

.wrapperHorizontal {
  @media (min-width: $bp2) {
    display: flex;
    padding: 30px 24px;
  }
}

.wrapper {
  padding: 24px;
}

.reviewText {
  p {
    margin: 24px 0;
  }

  @media (min-width: $bp2) {
    p {
      margin: 32px 0;
    }
  }
}

.reviewName {
  font-size: 24px;
  font-weight: 700;
}

.reviewNameWide {
  @media (min-width: $bp2) {
    order: 1;
  }
}

.reviewTextWide {
  @media (min-width: $bp2) {
    padding-left: 40px;
    display: flex;
    flex-direction: column;
    p {
      margin: 16px 0 0;
    }
  }
}

.reviewTextContent {
  @media (min-width: $bp2) {
    order: 2;
  }
}
