@import 'src/styles/variables';

.singleImageWrapper {
  margin: 0 auto;
  margin-top: 32px;
  width: 100%;
  max-width: 90vw;

  @media (min-width: $bp1) {
    width: '100%';
    max-width: none;

    &.withWidth {
      max-width: 100%;
    }
  }

  @media (min-width: $bp2) {
    margin-top: 40px;
  }
}

.theImage {
  border-radius: 4px;
}
