@import 'src/styles/variables';

.wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;

  @media (min-width: $bp1) {
    display: grid;
    gap: 16px;
  }

  @media (min-width: $bp2) {
    gap: 32px;
  }

  @media (min-width: $bp4) {
    width: auto;
  }
}

.item {
  margin-bottom: 20px;
  @media (min-width: $bp1) {
    margin-bottom: 0;
  }
}
