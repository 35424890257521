@import 'src/styles/variables';

.linkStyles {
  display: block;
  position: relative;
  width: 90vw;
  margin: 50px auto;
  max-width: 100%;
  border-radius: 4px;
  overflow: hidden;

  @media (min-width: $bp1) {
    margin: 80px auto;
  }

  @media (min-width: $bp2) {
    margin: 80px auto;
  }

  @media (min-width: $bp4) {
    width: auto;
  }
}
