@import 'src/styles/variables';

.textImageWrapper {
  margin: 0 auto;
  max-width: 100%;
  margin-top: 32px;

  @media (min-width: $bp1) {
    display: grid;
    gap: 32px;
    margin-top: 40px;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }

  @media (min-width: $bp4) {
    width: auto;
  }
}

.text {
  margin-bottom: 20px;

  @media (min-width: $bp1) {
    margin-bottom: 0;
  }
}

.cta {
  width: 100%;
  display: inline-flex;
  justify-content: center;
  color: $color-dark;
  border: 2px solid $color-dark;
  border-radius: 15px;
  padding: 4px 15px 6px;
  text-decoration: none;
  font-weight: 700;
  transition: all 0.1s ease-in-out;
  line-height: 1.4;

  &:hover {
    border-color: $color-black;
    color: $color-black;
  }

  @media (min-width: $bp2) {
    width: auto;
  }
}
