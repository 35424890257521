@import 'src/styles/mixins';
@import 'src/styles/variables';

.container {
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 32px;

  @media (min-width: $bp1) {
    display: flex;
    margin-top: 40px;
  }
}

.paidContainer {
  background-color: $color-attention0;
}

.freeContainer {
  background-color: $color-gray0;
}

.image {
  position: relative;
  width: 100%;
  height: 250;

  @media (min-width: $bp1) {
    width: auto;
    height: auto;
    flex: 1;
  }
}

.textContent {
  padding: 24px;

  @media (min-width: $bp1) {
    position: static;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  @media (min-width: $bp2) {
    padding: 32px;
    flex: 2;
  }

  @media (min-width: $bp3) {
    padding: 40px;
    flex: 3;
  }

  p {
    margin-top: 0;
    margin-bottom: 24px;
  }
}

.title {
  font-family: var(--poppins-font);
  font-size: 24px;
  font-weight: 900;

  @media (min-width: $bp2) {
    font-size: 32px;
  }
}

.bannerCTA {
  @include button;
  display: block;
  text-decoration: none;
  margin-top: 24px;
  text-align: center;

  @media (min-width: $bp1) {
    align-self: flex-start;
  }
}
