@import 'src/styles/variables';

.desktopHeaderItem {
  display: none;

  @media (min-width: $bp2) {
    display: flex;
    align-items: center;
  }
}

.desktopHeaderUser {
  display: none;

  @media (min-width: $bp2) {
    display: inline-flex;
    align-items: center;
    position: relative;
  }
}

.logoLink {
  display: inline-flex;
  margin: 32px;
  flex-direction: column;
  justify-content: center;

  @media (min-width: $bp2) {
    margin: 0;
  }
}

.toggle {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 20;

  @media (min-width: $bp2) {
    display: none;
  }
}

.menuOverlay {
  width: 100%;
  background-color: $color-attention0;
  overflow: hidden;
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 20;

  @media (min-width: $bp2) {
    display: none;
  }
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 32px;

  img {
    align-self: flex-start;

    @media (min-width: $bp1) {
      align-self: center;
    }
  }
}
