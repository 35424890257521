.main {
  margin-top: 50px;
}

@media (min-width: 900px) {
  .main {
    margin-top: 150px;
  }
}

.titleWrapper {
  grid-column: col / span 8;
}

@media (min-width: 900px) {
  .titleWrapper {
    grid-column: col / span 4;
  }
}

.heading {
  composes: h1 from global;
  margin-top: 0;
  margin-bottom: 0.67em;
}

.subtitle {
  font-size: 0.5em;
  display: block;
  width: fit-content;
  background-color: var(--color-dark);
  color: var(--color-white);
  padding: 6px 12px;
  border-radius: 4px;
  font-weight: 900;
  margin-top: 8px;
}

@media (min-width: 900px) {
  .subtitle {
    font-size: 0.3em;
    padding: 8px 16px;
  }
}

.descriptionSection {
  max-width: 780px;
}

.coverImage {
  border-radius: 4px;
}

.coverWrapperMobile {
  grid-column: col / span 8;
}

@media (min-width: 900px) {
  .coverWrapperMobile {
    display: none;
  }
}

.coverWrapperDesktop {
  display: none;
}

@media (min-width: 900px) {
  .coverWrapperDesktop {
    display: block;
    grid-column: col 5 / span 4;
    grid-row: 1;
  }
}

.whatIsWrapper {
  grid-column: col / span 8;
}

@media (min-width: 900px) {
  .whatIsWrapper {
    grid-column: col / span 6;
  }
}

.constentSection {
  margin-top: 100px;
}

.whatIsSection {
  margin-top: 60px;
}

@media (min-width: 900px) {
  .whatIsSection {
    margin-top: 120px;
  }
}

.ctaContainer {
  grid-row: 1;
  grid-column: col 7 / span 2;
  display: none;
  z-index: 10;
}

@media (min-width: 900px) {
  .ctaContainer {
    display: block;
    position: relative;
  }
}

.ctaBottomWrapper {
  position: sticky;
  bottom: 0;
}

@media (min-width: 900px) {
  .ctaBottomWrapper {
    display: none;
  }
}
