@import 'src/styles/variables';
@import 'src/styles/mixins';

.base {
  max-width: 1600px;
  margin: 0 auto;

  @media (min-width: $bp4) {
    display: grid;
    gap: 64px;
    grid-template: 'title title' 'aside body' 'bottom bottom' / 480px 1fr;
  }
}

.head {
  @include baseGrid;
  @media (min-width: $bp4) {
    grid-area: aside;
    width: 100%;
  }
}

.headData {
  @media (min-width: $bp1) {
    display: flex;
  }

  @media (min-width: $bp4) {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.body {
  @include baseGrid;
  @media (min-width: $bp2) {
    max-width: 1024px;
  }

  @media (min-width: $bp4) {
    width: auto;
    max-width: none;
    grid-area: body;
    padding: 0;
  }
}

.bottom {
  @include baseGrid;
  grid-area: bottom;

  @media (min-width: $bp4) {
    width: auto;
    margin: 0;
  }
}

.stickyHead {
  @media (min-width: $bp4) {
    position: sticky;
    top: 100px;
  }
}

.titleArea {
  grid-area: title;

  @media (min-width: $bp4) {
    display: none;
  }
}

.bodyTitleArea {
  display: none;

  @media (min-width: $bp4) {
    grid-area: body;
    display: block;
  }
}

.bodyTOC {
  @media (min-width: $bp4) {
    display: none;
  }
}

.asideTOC {
  display: none;

  @media (min-width: $bp4) {
    display: block;
    position: sticky;
    top: 100px;
    z-index: 10;
    max-height: calc(100vh - 100px);
    overflow: auto;
  }
}

.meta {
  @media (min-width: $bp1) {
    width: 60%;
    padding-left: 20px;
  }

  @media (min-width: $bp2) {
    padding-left: 50px;
  }

  @media (min-width: $bp4) {
    width: 100%;
    padding-left: 0;
    flex: 1;
  }
}
