.container {
  display: none;
  position: fixed;
  width: 240px;
  background-color: var(--color-attention0);
  border-radius: 4px;
  padding: 0 16px 16px;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 900px) {
  .container {
    display: flex;
  }
}

.priceArea {
  width: 116px;
  height: 116px;
  background-color: var(--color-attention);
  border-radius: 4px;
  transform: rotate(45deg) translate(-41px, -41px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.priceArea div {
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: rotate(-45deg);
}

.text {
  composes: body from global;
  text-align: center;
  margin: -16px 0 24px;
  padding: 0 8px;
}

.submit {
  margin-top: 24px;
}
