@import 'src/styles/variables';

.actions {
  margin-top: 20px;
}

.label {
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 700;

  &.optional {
    &:after {
      content: '(opcional)';
      text-transform: lowercase;
      font-weight: 400;
      font-size: 12px;
    }
  }
}

.error {
  font-size: 16px;
  font-weight: 700;
  color: $color-error;
}

.serverError {
  color: $color-errorActive;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.2;
  background-color: $color-errorBackground;
  padding: 20px;
  border-radius: 4px;
  margin-bottom: 20px;

  span {
    display: block;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
  }

  strong {
    font-weight: 700;
  }

  a {
    color: currentColor;
    text-decoration: underline;
    font-weight: 700;
  }
}

.input {
  border-radius: 4px;
  border: 1px solid;
  line-height: 2;
  padding: 4px 16px;
  width: 100%;
  color: $color-dark;
  background-color: $color-white;
  border-color: $color-gray3;

  &:focus {
    box-shadow: 0 0 0 1px $color-potus;
    outline: none;
  }

  &::placeholder {
    color: $color-dark3;
    font-weight: 700;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
  }

  &.hasValue {
    background-color: $color-gray0;
    border-color: $color-greenText;
    box-shadow: 0 0 0 1px $color-dark;
  }

  &.hasError {
    background-color: $color-pink;
    border-color: $color-red1;
  }
}

.textarea {
  border: 1px solid $color-gray;
  background-color: transparent;
  border-radius: 4px;
  color: $color-dark;
  padding: 12px 12px 14px;
  outline: none;
  width: 100%;
  margin-top: 5px;
  font-size: 1.1rem;
  font-weight: 500;
  --webkit-appearance: none;

  &:focus {
    box-shadow: 0 0 0 1px $color-potus;
    outline: none;
  }

  ::placeholder {
    font-weight: 700;
    font-size: 14px;
  }

  &.hasValue {
    background-color: $color-gray0;
    box-shadow: 0px 0px 0px 1px $color-dark;
    border-color: $color-dark;
  }

  &.hasError {
    background-color: $color-pink;
    border-color: $color-red1;
  }
}

.fieldBox {
  position: relative;

  &:not(:first-of-type) {
    margin-top: 20px;
  }
}
