@import 'src/styles/variables';

.letterWrapper {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: $color-bottle;
}

.profileLink {
  text-decoration: none;
  display: flex;
  align-items: center;
}

.name {
  margin-left: 8px;
  color: $color-gray3;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  font-size: 16px;

  @media (min-width: $bp2) {
    display: none;
  }

  @media (min-width: $bp3) {
    display: inherit;
  }
}

.loginLink {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  --reach-combobox: 1;
  --reach-dialog: 1;
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
  font-family: 'Poppins';
  color: #1e2e28;
  padding: 16px 24px;
  border-radius: 4px;
  border: none;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  cursor: pointer;
  transition: all 0.075s ease-in-out;
  background-color: #ecf4f1;
  text-decoration: none;
  -webkit-box-pack: start;
  justify-content: flex-start;
  position: relative;

  @media (min-width: $bp2) {
    justify-content: flex-start;
    position: relative;
  }
}

.firstLetter {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  clip-path: polygon(50% 0px, 100% 50%, 50% 100%, 0px 50%);
  text-transform: uppercase;
  font-weight: 700;
  font-size: 10px;
  color: $color-bottle;
  background-color: $color-tejaLight;
}

.avatar {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;

  @media (min-width: $bp1) {
    width: 40px;
    height: 40px;
  }
}
