@import 'src/styles/variables';

.container {
  border-top: 3px solid $color-lightGrey;
  border-bottom: 3px solid $color-lightGrey;
  margin-top: 32px;
  margin-bottom: 32px;

  p {
    line-height: 1.4;
  }

  @media (min-width: $bp4) {
    margin-top: 0;
    width: auto;
  }
}
