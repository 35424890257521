@import 'src/styles/variables';

.quotesImage {
  width: 40px;
  margin-bottom: 16px;
  transform: rotate(180deg);
}

.featuredContainer {
  margin-top: 40px;

  @media (min-width: $bp1) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 32px;
  }
}

.featuredItemContainer {
  background-color: $color-gray020;
  border-radius: 4px;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.openButton {
  background-color: transparent;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 24px;
  line-height: 28px;
  border-radius: 4px;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px $color-gray4;
  }
}

.overlay {
  &[data-reach-dialog-overlay] {
    background-color: $color-white;
    z-index: 20;

    @media (min-width: $bp1) {
      overflow: hidden;
      background: hsla(0, 0%, 0%, 0.33);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.overlayContent {
  &[data-reach-dialog-content] {
    margin: 0;
    width: 100%;
    position: relative;
    padding: 0;
    height: 100%;
    max-width: 744px;

    @media (min-width: $bp1) {
      position: static;
      width: 80%;
      height: 80%;
      border-radius: 4px;
      overflow: hidden;
    }
  }
}

.overlayHeader {
  padding: 24px;
  background-color: $color-white;
  z-index: 25;
  position: sticky;
  top: 0;

  @media (min-width: $bp1) {
    height: 140px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 48px 48px 0;
  }
}

.headerText {
  @media (min-width: $bp1) {
    order: 1;
  }
}

.quotes {
  transform: rotate(180deg);
  width: 40px;
  margin-bottom: 8px;

  @media (min-width: $bp1) {
    width: 60px;
    order: 2;
    margin-top: 0;
    transform: rotate(180deg) translateY(10px);
  }
}

.closeButton {
  position: fixed;
  top: 24px;
  right: 24px;
  background-color: $color-gray4;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: $color-white;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 30;

  &:focus {
    outline: none;
    border: 2px solid $color-white;
    box-shadow: 0 0 0 3px $color-gray4;
  }

  @media (min-width: $bp1) {
    position: absolute;
    top: 8px;
    right: 8px;
    color: $color-dark;
    background-color: $color-white;
    border: none;
    cursor: pointer;

    &:focus {
      border: none;
      box-shadow: none;
    }
  }
}

.overlayTitle {
  font-family: var(--poppins-font);
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin: 0;

  @media (min-width: $bp1) {
    font-size: 32px;
    line-height: 40px;
  }
}

.overlaySubtitle {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  color: $color-gray3;
}

.animated {
  position: relative;
  height: 100%;
}

.list {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;

  @media (min-width: $bp1) {
    padding: 24px;
    height: calc(100% - 140px);
  }
}

.reviewItem {
  &:not(:first-of-type) {
    margin-top: 16px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      width: calc(100% - 48px);
      margin: 0 auto;
      left: 24px;
      height: 1px;
      background-color: $color-gray4;

      @media (min-width: $bp1) {
        width: calc(100% - 144px);
        left: 110px;
      }
    }
  }
}
