@import 'src/styles/variables';

.container {
  display: flex;
  align-items: flex-end;
  margin-top: 20px;
}

.thumbnail {
  display: block;
  width: 56px;
  border-radius: 4px;
  margin-right: 15px;
  box-shadow: 0px 0px 1px 1px $color-dark3;
}

.link {
  display: flex;
  flex-direction: column;
  color: $color-dark;
  margin-left: 16px;
}

.pinitText {
  font-weight: 700;
  line-height: 1.4;
}
