@import 'src/styles/mixins';

.container {
  display: flex;
  gap: 8px;
  align-items: baseline;
  margin-top: 20px;
}

.listTitle {
  font-weight: 700;
}

.list {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 0;
  margin: 0;
}

.tagText {
  @include textBadge(10px);
  background-color: $color-gray0;
  color: $color-dark;
  transition: background-color 0.1s ease-in-out;
  letter-spacing: 0.1rem;

  a:hover & {
    background-color: $color-gray0Hover;
  }
}
