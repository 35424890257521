@import 'src/styles/variables';

.textBlockWrapper {
  margin: 0 auto;
  margin-top: 32px;

  :global {
    .block-img {
      margin: 20px 0;
      @media (min-width: $bp2) {
        margin: 50px 0;
      }
    }
  }

  p:first-of-type {
    margin: 0;
  }

  p:last-of-type {
    margin: 0;
  }

  h2 {
    margin-top: 56px;
  }

  h3 {
    margin-top: 32px;
  }

  h4 {
    margin-top: 32px;
  }

  @media (min-width: $bp2) {
    margin-top: 40px;

    h2 {
      margin-top: 80px;
    }

    h3 {
      margin-top: 56px;
    }
  }
}
