@import 'src/styles/variables';

.subheading {
  padding: 20px;
  border-radius: 4px;
  background-color: $color-attentionActive;
  margin: 50px auto;
  max-width: 800px;
  text-align: center;

  @media (min-width: $bp2) {
    margin: 100px auto;
  }

  h2 {
    font-size: 20px;
    line-height: 1.5;
    margin: 0;

    @media (min-width: $bp2) {
      font-size: 26px;
    }
  }
}
