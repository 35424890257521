@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  @include baseGrid;

  h1 {
    margin: 30px 0;
    line-height: 1.3;
  }

  @media (min-width: $bp2) {
    h1 {
      margin: 50px 0;
    }
  }

  @media (min-width: $bp4) {
    width: auto;
    margin-left: 0;

    h1 {
      margin-top: 0;
    }
  }
}
