@import 'src/styles/variables';

.wrapper {
  overflow: hidden;
}

.container {
  display: flex;
}

.slide {
  position: relative;
  min-width: 100%;

  img {
    border-radius: 4px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.navigation {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.dot {
  width: 10px;
  height: 10px;
  padding: 0;
  margin: 2px;
  cursor: pointer;
  border: 2px solid $color-dark;
  border-radius: 50%;
  background-color: transparent;

  &.selected {
    background-color: $color-dark;
  }

  :focus {
    outline: none;
    box-shadow: 0px 0px 3px 2px $color-attentionActive;
  }
}
