.container {
  width: 100vw;
  background-color: var(--color-gray0);
  padding: 16px 32px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.container a {
  flex-shrink: 0;
}

@media (min-width: 600px) {
  .container {
    flex-direction: row;
  }
}

.priceText {
  display: flex;
  align-items: flex-start;
}

.priceArea {
  display: flex;
  flex-direction: column;
}

.priceArea span {
  font-weight: 600;
}

.description {
  padding: 0 16px;
  display: block;
}

.description p {
  margin: 0;
  line-height: 1;
  font-size: 18px;
}
