@import 'src/styles/variables';

.container {
  width: 90vw;
  max-width: 100%;
  margin: 30px auto;
  background-color: $color-gray0;
  padding: 20px;
  border-radius: 4px;

  @media (min-width: $bp4) {
    width: auto;
  }
}

.toggleTitle {
  background-color: transparent;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  cursor: pointer;

  :focus {
    outline: none;
  }

  svg {
    transform: translate(10px, 1px);
  }
}

.title {
  margin: 0;
  color: $color-dark;
  font-family: var(--poppins-font);
}
