@import 'src/styles/variables';

.container {
  margin-top: 30px;
  border: 1px solid $color-gray;
  padding: 20px;
  border-radius: 4px;

  @media (min-width: $bp1) {
    margin-top: 0;
    max-width: 500px;
  }

  @media (min-width: $bp4) {
    margin-top: 24px;
    max-width: none;
  }
}

.top {
  display: flex;
  align-items: center;
}

.nameAndFollow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 10px;
  align-items: center;

  @media (min-width: $bp3) {
    padding-left: 20px;
  }
}

.name {
  font-size: 20px;
  margin-right: 10px;
  font-weight: 700;
}

.bio {
  margin-top: 20px;

  p {
    margin: 0;
    font-size: 18px;
  }
}

.followLink {
  text-decoration: none;
  background-color: $color-lightGrey;
  color: $color-dark;
  display: inline-flex;
  justify-content: center;
  border: 1px solid $color-dark3;
  border-radius: 12px;
  align-items: center;
  padding: 4px 14px 5px;
  line-height: 1.4;
  align-self: flex-start;
  transition: border-color 0.1s ease-in-out;

  span {
    font-weight: 700;
  }

  svg {
    margin-left: 5px;
  }

  @media (min-width: $bp2) {
    &:hover {
      border-color: $color-dark;
    }
  }
}

.avatar {
  border-radius: 50%;
  box-shadow: 0 0 0 5px $color-white;
}
