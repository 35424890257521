@import 'src/styles/variables';
@import 'src/styles/mixins';

.downloadLinkButton {
  background-color: $color-attention;
  border: none;
  font-size: 16px;
  border-radius: 4px;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
  color: $color-dark;

  &:focus {
    box-shadow: 0 0 0 5px $color-white, 0 0 2px 7px $color-attention;
    outline: none;
  }

  @media (min-width: 900px) {
    &:hover {
      background-color: $color-attentionHover;
    }
  }
}

.name {
  text-transform: uppercase;
  font-weight: 500;
  display: block;
  margin-right: 10px;
  letter-spacing: 0.2rem;
}

.info {
  display: flex;
  align-items: center;
}

.fileSize {
  font-size: 16px;
  margin-right: 10px;
  margin-left: 5px;
}

.spinner {
  width: 32px;
  height: 32px;
  margin-left: 10px;
}

.download {
  @include isVisuallyHidden;
}
