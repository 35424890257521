@import 'src/styles/variables';

.base {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid $color-gray0;
  border-radius: 4px;

  img {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    display: block;
  }

  ul + &,
  & + & {
    margin-top: 16px;

    @media (min-width: $bp3) {
      margin-top: 24px;
    }
  }
}

.caption {
  font-size: 16px;
  font-weight: 700;
  padding: 10px 0;
  line-height: 1.5;
  padding-left: 16px;

  p:first-of-type {
    margin-top: 0;
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  a {
    color: $color-dark;

    &:hover {
      text-decoration: underline;
    }
  }
}
