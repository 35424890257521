@import 'src/styles/variables';

.flag {
  box-shadow: 0 0 0 2px $color-white;
  width: 20px;
  height: 20px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.trigger {
  display: flex;
  align-items: center;
  svg {
    transform: translate(5px, 2px);
  }
}

.scrollIcon {
  background-color: $color-bottleLight;
  padding: 16px;
  display: inline-block;
}

.triggerStyles {
  all: unset;
  background-color: $color-white;
  border: 1px solid $color-dark;
  width: 100%;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 0 16px;
  gap: 8px;
  color: $color-dark;
  line-height: 2;

  &:focus {
    box-shadow: 0 0 0 1px $color-potus;
  }

  &.error {
    background-color: $color-pink;
    border: 1px solid $color-red1;
  }
}

.selectContent {
  z-index: 10;
  overflow: hidden;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
}

.selectViewport {
  padding: 8px;
  z-index: 10;
}

.selectItem {
  all: unset;
  color: $color-dark;
  display: flex;
  align-items: center;
  line-height: 1.6;
  user-select: none;

  &[data-disabled] {
    color: $color-gray;
  }

  &:focus {
    background-color: $color-gray020;
    color: $color-potus;
  }
}
