@import 'src/styles/variables';

.list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 24px;
  margin-top: 40px;
}

.text {
  display: flex;
  flex-direction: column;
}

.answer {
  color: $color-gray3;
}

.item {
  display: flex;

  :not(:last-of-type) {
    margin-bottom: 30px;
  }
}

.question {
  display: block;
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  margin-bottom: 8px;
}

.icon {
  flex-shrink: 0;
  margin-right: 16px;
  transform: translateY(7px);
}
