@import 'src/styles/variables';

.enableContainer {
  background-color: $color-gray0;
  padding: 40px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
}

.commentDate {
  padding: 4px 0;
  display: inline-block;
  visibility: hidden;

  &.visible {
    visibility: visible;
  }
}

// AddComment

.addContainer {
  margin-bottom: 16px;
}

.addTitle {
  font-size: 24px;
  font-weight: 700;
}

.errorContainer {
  margin-bottom: 24px;
}

.inputBox {
  margin-bottom: 16px;
}

.successTitle {
  font-family: var(--poppins-font);
  font-size: 32px;
  line-height: 40px;
  font-weight: 600;
}

.success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  background-color: $color-potus;
  color: $color-white;
  padding: 24px;
  border-radius: 4px;
  min-height: 100%;

  p {
    margin: 0;
    text-align: center;
  }
}

.container {
  margin-top: 56px;

  @media (min-width: $bp2) {
    margin-top: 80px;
  }

  @media (min-width: $bp4) {
    padding: 0;
  }
}

.grid {
  display: grid;
  margin-top: 32px;
  gap: 32px;
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));

  @media (min-width: $bp1) {
    grid-template-columns: repeat(auto-fill, minmax(calc(50% - 1rem), 1fr));
    margin-top: 40px;
  }

  @media (min-width: $bp3) {
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  }
}

.commentCard {
  background-color: $color-gray020;
  padding: 30px;
  border-radius: 4px;
}

.longComment {
  @media (min-width: $bp1) {
    grid-column: span 2;
  }
}

.commentName {
  margin-left: 16px;
  color: $color-attentionDark1;
  font-weight: 700;
  font-size: 20px;
}

.commentText {
  padding: 16px 0;
  font-size: 24px;

  p:first-of-type {
    margin-top: 0;
  }
}

.avatarName {
  img {
    border-radius: 50%;
  }
}

.avatar {
  display: inline-block;
  width: 40px;
  height: 40px;
}

.loading {
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 24px;
    @media (min-width: $bp2) {
      width: 48px;
    }
  }
}

.commentContainer {
  max-height: 300px;
  overflow: auto;
}
