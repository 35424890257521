.name {
  font-size: 24px;
  font-weight: 700;
  margin-right: 20px;
}

.top {
  display: flex;
  align-items: center;
}

.label {
  display: inline-flex;
  align-items: center;
  padding: 10px 0;
}

.description {
  maxwidth: 600px;
}
